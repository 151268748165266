import config from '@/config'
import Ajax from '@/libs/ajax'

const ajax = new Ajax(config.serverConfig.serviceURL);

// 系统设置
const getValue = ajax.post(config.apiConf.serviceApi + 'setting/switch/value');
// 产品列表
const saveValue = ajax.post(config.apiConf.serviceApi + 'setting/switch/save');

export default {
  getValue,
  saveValue
}
