var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading"
        }
      ],
      staticClass: "page-box page-box-setting"
    },
    [
      _c("lz-page-panel", { attrs: { title: "系统设置" } }, [
        _c(
          "div",
          { staticClass: "setting-body" },
          _vm._l(_vm.settingItems, function(item, index) {
            return _c("div", { key: item.id }, [
              item.isShow
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: item.loading,
                          expression: "item.loading"
                        }
                      ],
                      staticClass: "setting-item mr-20 pv-20 pl-20"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "display__flex justify_content__space_between"
                        },
                        [
                          _c("p", { staticClass: "item-title" }, [
                            _vm._v(_vm._s(item.title))
                          ]),
                          _c("el-switch", {
                            attrs: { width: 36 },
                            on: {
                              change: function($event) {
                                return _vm.handleSwitchChange(index)
                              }
                            },
                            model: {
                              value: item.isOpen,
                              callback: function($$v) {
                                _vm.$set(item, "isOpen", $$v)
                              },
                              expression: "item.isOpen"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "item-content mt-15" }, [
                        _vm._v(_vm._s(item.content))
                      ])
                    ]
                  )
                : _vm._e()
            ])
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }